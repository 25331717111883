import ProgressBar from '@badrap/bar-of-progress';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SyncIcon from '@material-ui/icons/Sync';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import clsx from 'clsx';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';

import { defaultProgressBarOptions, monthNames } from '../../config';
import { forcedSignOut, round } from '../../utils';
import { useStyles } from './styles';
import { RenderLineProperties, SyncStatusProperties, TabPanelProperties } from './SyncStatus.d';

const a11yProperties = (index: number): { 'aria-controls': string; id: string } => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const SyncStatus: FC<SyncStatusProperties> = ({ redmine, resourceGuru }) => {
  const progress = new ProgressBar(defaultProgressBarOptions);
  const classes = useStyles();
  const [activeTab, setActiveTav] = React.useState(0);

  const updateData = async <T extends Types.Components.SyncStatus.updateData>(range: T): Promise<void> => {
    const from = range.from && `from=${range.from}`;
    const to = range.to && `to=${range.to}`;
    const query = [from, to].filter(Boolean).join('&');
    const { type } = range;

    progress.start();
    const result = await fetch(`/api/data/${type}Sync?${query}`);

    forcedSignOut(result.status);

    progress.finish();
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, tabIndex: number): void => setActiveTav(tabIndex);

  const renderLine = (
    { type, date, max, local, remote, from, to }: RenderLineProperties,
    index: number,
    list: RenderLineProperties[],
  ): JSX.Element => {
    const localRedmineProgress = round((Number(local) * 100) / max);
    const remoteRedmineProgress = round((Number(remote) * 100) / max);
    const isSynced = round(localRedmineProgress) === round(remoteRedmineProgress);
    const isLast = index === list.length - 1;

    return (
      <TimelineItem key={date} className={classes.timelineItem}>
        <TimelineOppositeContent>
          {local && (
            <div
              className={clsx(classes.progressBar, {
                [classes.synced]: isSynced,
              })}
              role="progressbar"
              style={{ width: `${localRedmineProgress}%`, marginLeft: 'auto' }}
            >
              <div className={classes.progressBarTitle} style={{ right: 0 }}>
                <NumberFormat thousandSeparator value={local} displayType="text" decimalScale={2} fixedDecimalScale />
              </div>
            </div>
          )}
        </TimelineOppositeContent>

        <TimelineSeparator className={classes.separator}>
          {!isSynced && (
            <Tooltip title={`Sync ${date} (${from} - ${to})`}>
              <IconButton color="primary" aria-label="Sync" size="small" onClick={() => updateData({ from, to, type })}>
                <SyncIcon />
              </IconButton>
            </Tooltip>
          )}
          <div className={classes.date}>{date}</div>
          {!isLast && <TimelineConnector className="timelineConnector" />}
        </TimelineSeparator>

        <TimelineContent>
          {remote && (
            <div
              className={clsx(classes.progressBar, {
                [classes.synced]: isSynced,
              })}
              role="progressbar"
              style={{ width: `${remoteRedmineProgress}%` }}
            >
              <div className={classes.progressBarTitle}>
                <NumberFormat thousandSeparator value={remote} displayType="text" decimalScale={2} fixedDecimalScale />
              </div>
            </div>
          )}
        </TimelineContent>
      </TimelineItem>
    );
  };

  const TabPanel = (props: TabPanelProperties): JSX.Element => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const RenderHeader = (): JSX.Element => (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Local Hours
            </Typography>
          </Paper>
        </TimelineOppositeContent>

        <TimelineSeparator className={classes.separator} />

        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              Remote Hours
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );

  return (
    <div className={classes.root}>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="Redemine and Resource Guru visualization"
      >
        <Tab label="Redmine" {...a11yProperties(0)} />
        <Tab label="ResourceGuru" {...a11yProperties(1)} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <RenderHeader />
        {redmine.days.length > 0 && (
          <>
            <Typography variant="h6" align="center">
              {monthNames[new Date().getMonth()]}
            </Typography>
            <Timeline>{redmine.days.map(renderLine)}</Timeline>
          </>
        )}
        {redmine.months.length > 0 && (
          <>
            <Typography variant="h6" align="center">
              {new Date().getFullYear()}
            </Typography>
            <Timeline>{redmine.months.map(renderLine)}</Timeline>
          </>
        )}
        {redmine.years.length > 0 && (
          <>
            <Typography variant="h6" align="center">
              Years
            </Typography>
            <Timeline>{redmine.years.map(renderLine)}</Timeline>
          </>
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <RenderHeader />
        {resourceGuru.months.length > 0 && (
          <Timeline className={classes.resourceGuruMonths}>{resourceGuru.months.map(renderLine)}</Timeline>
        )}
      </TabPanel>
    </div>
  );
};
